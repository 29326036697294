import { MenuItem, Typography } from '@mui/material';
import CustomMenu from './index';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import FunctionsIcon from '@mui/icons-material/Functions';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { profJimBrandColors } from '../../../theme/variants';

type TextFieldCustomMenuProps = {
  instanceId: string;
  gameOptionId: string;
};


const TextFieldCustomMenu: React.FC<TextFieldCustomMenuProps> = ({instanceId, gameOptionId}) => {
  const handleMenuOptionImageClick = () => {
    let sendData = { messageType: 'uploadAndSelectGameOptionsImageFromParent', instanceId: instanceId, gameOptionId: gameOptionId };
    window.parent.postMessage(sendData, '*')
  };

  return (
    <CustomMenu>
      <MenuItem
        sx={{
          minWidth: '50px',
        }}
        onClick={handleMenuOptionImageClick}
      >       
       <AddPhotoAlternateIcon
            className='add-option-image'
            sx={{
              cursor: 'pointer',
              fontSize: '24px',
              color: profJimBrandColors.purple,
              marginRight: '5px',
            }}
          />
          Upload Image
      </MenuItem>
    </CustomMenu>
  );
};

export default TextFieldCustomMenu;
