import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

type Props = {
  children?: React.ReactNode
};

const GamesLayout: React.FC<Props> = ({ children }) => {
  const history = useHistory();
  const search = window.location.search;
  const searchParams = new URLSearchParams(search);
  const instanceId = searchParams.get('instanceId');
  const gameId = searchParams.get('gameId');

  useEffect(() => {
    bindGameConfigureIframeMessageReciever();
    return () => {
      removeGameConfigureIFrameEvent(window, 'message', bindGameConfigureEvent);
    }
  }, []);

  const bindGameConfigureIframeMessageReciever = () => {
    bindConfigureIFrameEvent(window, 'message', bindGameConfigureEvent);
  }

  function bindGameConfigureEvent(e: any) {
    try {
      if (e.data && e.data.type != 'webpackWarnings') {
        const { messageType } = JSON.parse(e.data);
        if (messageType) {
          if (messageType === 'configure') {
            history.push('/configure/?instanceId=' + instanceId + "&gameId=" + gameId);
          }
        }
      }
    }
    catch (err) {
    }
  }

  function bindConfigureIFrameEvent(element: any, eventName: any, eventHandler: any) {
    if (element.addEventListener) {
      element.addEventListener(eventName, eventHandler, false);
    }
    else if (element.attachEvent) {
      element.attachEvent('on' + eventName, eventHandler);
    }
  }

  function removeGameConfigureIFrameEvent(element: any, eventName: any, eventHandler: any) {
    element.removeEventListener(eventName, eventHandler, false);
  }

  return <div>{children}</div>;
};

export default GamesLayout;
