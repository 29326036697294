import './App.css';
import GamesLayout from './components/GamesLayout';
import Page404 from './components/Page404';
import DragDropConfigure from './components/dragdrop/DragDropConfigure';
import DragDropGames from './components/dragdrop/DragDropGame';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
} from 'react-router-dom';

function App() {
  return (
    <div className="App">
    <Router>
      <Switch>
        <Route
          path="/"
          exact
          render={(props: RouteComponentProps) => (
            <GamesLayout>
              <DragDropGames />
            </GamesLayout>
          )}
        />
        <Route
          path="/configure"
          exact
          render={(props: RouteComponentProps) => (
            <GamesLayout>
              <DragDropConfigure />
            </GamesLayout>
          )}
        />
        <Route
          render={() => (
            <Page404 />
          )}
        />
      </Switch>
    </Router>
    </div>
  );
}

export default App;
