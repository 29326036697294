import { green, grey, indigo, red } from '@mui/material/colors';
import merge from 'deepmerge';

export const profJimBrandColors = {
  purple: '#742358',
  orange: '#FF5C54',
  yellow: '#FFCE39',
  darkYellow: '#f59218',
  lightgrey: '#A5A0A0',
  toolTextColor: '#8B8B8B',
  cyan: '#249EA4',
  grey: '#707070',
  courseCardColor: '#B5B5B5',
  courseCardBorderColor: '#707070',
  black: '#3E3E3F',
  blue: '#1E4EF4',
  red: '#F91F2F',
  green: '#78C257',
  darkgreen: '#47897A',
  white: '#ffffff',
  cardBorder: '#707070',
  cardContentDefault: '#707070',
  cardContentAlt: '#FFFFFF',
  cardBackground: '#F8F8F8',
  dialogBackdrop: 'rgba(255, 32, 24, 0.2)',
  secondaryButton: '#B9B6B6',
  textSecondary: '#9B9B9B',
  inactive: '#B2B2B2',
  toolbarHoverColor: '#D2C6CF',
  toolbarBackgroundCOlor: '#DED1DA',
};
