//@ts-nocheck
import React, { useEffect, useState, useRef } from 'react';
import './DragDropGame.css';
import { GameConfiguration } from '../../utils/Modals';
import { checkIfTextIsURL } from '../../utils/utils';
import { NavLink } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { profJimBrandColors } from '../../theme/variants';
import $ from 'jquery';
import { Backdrop } from '@mui/material';
import { debug } from 'console';
import ConfettiCanvas from '../common/ConfettiCanvas';
import CheersOnCorrectAnswer from '../common/CheersOnCorrectAnswer';

function DragDropGame() {
  const search = window.location.search;
  const searchParams = new URLSearchParams(search);
  const instanceId = searchParams.get('instanceId');
  const gameId = searchParams.get('gameId');
  const [gameInstance, setGameInstance] = useState<any>();
  const queryStringLoaded = useRef<boolean>(false);
  const [showConfetti, setShowConfetti] = useState<any>(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const correctAnswerSound = 'https://uatprofjim.blob.core.windows.net/appassets/theme_thumbnails/crowd-cheer.mp3';

  useEffect(() => {
    if (instanceId && !queryStringLoaded.current) {
      queryStringLoaded.current = true;
      getGameConfigurationByGameInstanceIdFromParent(instanceId);
    }
    return () => {
      removeGameChildIFrameEvent(window, 'message', bindGameChildEvent);
    }
  }, []);

  useEffect(() => {
    if (gameInstance) {
      initiateGameInstance(gameInstance);
    }
  }, [gameInstance]);

  // Parent Communication---------------------------------------------------------
  const getGameConfigurationByGameInstanceIdFromParent = (instanceId) => {
    let sendData = { messageType: 'getGameConfigurationByGameInstanceIdFromParent', instanceId: instanceId };
    window.parent.postMessage(sendData, '*')
  }

  useEffect(() => {
    bindGameChildIframeMessageReciever();
    return () => {
      removeGameChildIFrameEvent(window, 'message', bindGameChildEvent);
    }
  }, []);

  const bindGameChildIframeMessageReciever = () => {
    bindIFrameEvent(window, 'message', bindGameChildEvent);
  }

  function bindGameChildEvent(e: any) {
    if (e.data && e.data.type != 'webpackWarnings') {
      try {
        const { messageType } = JSON.parse(e.data);
        if (messageType) {
          if (messageType === 'getGameConfigurationByGameInstanceIdFromParentData') {
            const { response, returnInstanceId } = JSON.parse(e.data);
            if (instanceId && returnInstanceId && instanceId === returnInstanceId)
              loadGameBasedOnGameInstanceId(response);
          }
        }
      }
      catch (err) {
      }
    }
  }

  function bindIFrameEvent(element: any, eventName: any, eventHandler: any) {
    if (element.addEventListener) {
      element.addEventListener(eventName, eventHandler, false);
    }
    else if (element.attachEvent) {
      element.attachEvent('on' + eventName, eventHandler);
    }
  }

  function removeGameChildIFrameEvent(element: any, eventName: any, eventHandler: any) {
    element.removeEventListener(eventName, eventHandler, false);
  }
  // Parent Communication---------------------------------------------------------

  const loadGameBasedOnGameInstanceId = (response) => {
    try {
      if (response && response.data && response.data.gameInstanceConfiguration) {
        let currentGameInstance: GameConfiguration = response.data.gameInstanceConfiguration;
        currentGameInstance.configuration = JSON.parse(currentGameInstance.configuration);
        document.getElementById('configure-message')!.style.display = 'none';
        document.getElementById('game-pane')!.style.display = 'block';
        document.getElementById('loader-pane')!.style.display = 'none';
        $('#success-message').css('display','none');
        $('#draggable-items').css('display','flex');
        setGameInstance(currentGameInstance);
      }
      else {
        document.getElementById('configure-message')!.style.display = 'block';
        document.getElementById('game-pane')!.style.display = 'none';
        document.getElementById('loader-pane')!.style.display = 'none';
        $('#success-message').css('display','none');
        $('#draggable-items').css('display','flex');
        setGameInstance(null);
      }
    }
    catch (err) {
    }
  }


  let correct = 0;
  let total = 0;
  enum elementType {
    source = 1,
    target = 2
  }
  const scoreSection = document.querySelector('.score') as HTMLElement;
  const correctSpan = scoreSection?.querySelector('.correct-answer') as HTMLElement;
  const correctAnswerSpan = scoreSection?.querySelector('.correct-answer-span') as HTMLElement;
  const totalSpan = scoreSection?.querySelector('.total-attempt') as HTMLElement;
  const playAgainBtn = document.getElementById(
    'start-over-btn',
  ) as HTMLButtonElement;

  const draggableItems = document.querySelector(
    '.draggable-items',
  ) as HTMLElement;
  const matchingPairs = document.querySelector(
    '.matching-pairs',
  ) as HTMLElement;
  let draggableElements;
  let droppableElements;

  function checkIfElementIsImage(element: any, elementDisplayType: elementType) {
    let isImage = false;
    if (elementDisplayType === elementType.source) {
      isImage = checkIfTextIsURL(element.source);
    }
    else {
      isImage = checkIfTextIsURL(element.target);
    }
    return isImage;
  }

  function getElementHTML(element: any, elementDisplayType: elementType, isDraggable, isTruncate = false) {
    let resultHTML = "";
    let isImage = false;
    let placeHolder = "";
    let elementInput = "";

    if (elementDisplayType === elementType.source) {
      isImage = checkIfTextIsURL(element.source);
      placeHolder = element.source;
      elementInput = "source";
    }
    else {
      isImage = checkIfTextIsURL(element.target);
      placeHolder = element.target;
      elementInput = "target";
    }

    if(!placeHolder)
    {
      placeHolder = "&nbsp;";
    }

    if (isImage) {
      resultHTML = `<img src="${placeHolder}" class="img-element ${isDraggable ? 'draggable' : ''}" draggable="${isDraggable ? true : false}" id="${element.id}-${elementInput}"></img> `;
    }
    else {
      resultHTML = `<div title="${placeHolder}" class="text-element ${isDraggable ? 'draggable' : ''}" draggable="${isDraggable ? true : false}"  id="${element.id}-${elementInput}">${elementDisplayType === elementType.target? (isTruncate && placeHolder.length > 35? placeHolder.substring(0, 35) + '...' : placeHolder) : 'drop here'}</div>`;
    }
    

    return {resultHTML, placeHolder, isImage};
  }

  function initiateGameInstance(game: any) {
    if (game) {
      document.getElementById("draggable-items")!.innerHTML = '';
      document.getElementById('matching-pairs')!.innerHTML = '';

      if(gameInstance.displayName)
      $('#game-header').text(gameInstance.displayName);

      if(gameInstance.instructions)
      $('#game-instructions').text(gameInstance.instructions);

      if(gameInstance.sentence)
      {
        $('#game-sentence').html(gameInstance.sentence);
      } 
      else
      {
        $('#game-sentence').css('display', 'none');
      }   
      const randomDraggableBrands = generateRandomItemsArray(gameInstance.configuration);
      const alphabeticallySortedRandomDroppableBrands = [...gameInstance.configuration]; // generateRandomItemsArray([...gameInstance.configuration]);

      //create target droppable
      for (let i = 0; i < alphabeticallySortedRandomDroppableBrands.length; i++) {
        let {resultHTML, placeHolder, isImage} = getElementHTML(alphabeticallySortedRandomDroppableBrands[i], elementType.source, false);
        let matchingPairsHTML = '';
        if(alphabeticallySortedRandomDroppableBrands[i] && (!alphabeticallySortedRandomDroppableBrands[i].target || alphabeticallySortedRandomDroppableBrands[i].target.length < 0))
        {
          matchingPairsHTML = ` <div> <div class="${!isImage? 'target-word' : 'target-image'}">${!isImage ? placeHolder: ''}</div><div class="matching-pair-blank">-</div></div>`;          
        }
        else
        {
          matchingPairsHTML =  ` <div> <div class="${!isImage? 'target-word' : 'target-image'}">${!isImage ? placeHolder : resultHTML}</div><div class="matching-pair">
            <span class="droppable" data-brand="${alphabeticallySortedRandomDroppableBrands[i].id}"> 
            <span class="target-label" style="pointer-events:none; color:#00A99C;">${!isImage? resultHTML : 'Drag here...'}</span>        
            </span>
          </div>
          </div>
        `; 
        }

        matchingPairs?.insertAdjacentHTML(
          'beforeend',
          matchingPairsHTML,
        );
      }

      // create draggable
      for (let i = 0; i < randomDraggableBrands.length; i++) {
        if(randomDraggableBrands[i] && randomDraggableBrands[i].target && randomDraggableBrands[i].target.length > 0)
        {
          let {resultHTML, placeHolder} = getElementHTML(randomDraggableBrands[i], elementType.target, true);
          draggableItems?.insertAdjacentHTML(
            'beforeend',
            `<div><div class="${checkIfElementIsImage(randomDraggableBrands[i], elementType.target) ? 'source-header-image-container' : 'source-header-text-container'}" title="Drag this above">${resultHTML}</div></div>`,
          );
        }
      }

      draggableElements = document.querySelectorAll('.draggable');
      droppableElements = document.querySelectorAll('.droppable');
      draggableElements.forEach(elem => {
        elem.addEventListener('dragstart', dragStart);
      });

      droppableElements.forEach(elem => {
        elem.addEventListener('dragenter', dragEnter);
        elem.addEventListener('dragover', dragOver);
        elem.addEventListener('dragleave', dragLeave);
        elem.addEventListener('drop', drop);
      });
    }
  }

  function dragStart(event: any) {
    event.dataTransfer.setData('text', event.target.id);
  }

  function dragEnter(event: any) {
    if (
      event.target.classList &&
      event.target.classList.contains('droppable') &&
      !event.target.classList.contains('dropped')
    ) {
      event.target.classList.add('droppable-hover');
    }
  }

  function dragOver(event: any) {
    if (
      event.target.classList &&
      event.target.classList.contains('droppable') &&
      !event.target.classList.contains('dropped')
    ) {
      event.preventDefault();
    }
  }

  function dragLeave(event: any) {
    if (
      event.target.classList &&
      event.target.classList.contains('droppable') &&
      !event.target.classList.contains('dropped')
    ) {
      event.target.classList.remove('droppable-hover');
    }
  }

  function checkIfCorrectAnswer(sourceId, targetId) {
    let isCorrectMatching = false;
    let correctDroppableBrand = null;
    sourceId = getOnlyId(sourceId);
    targetId = getOnlyId(targetId);

    if (sourceId == targetId) {
      isCorrectMatching = true;
      correctDroppableBrand = targetId;
    }
    else {
      let sourceOption = gameInstance.configuration.find(s => s.id === sourceId);
      let targetOption = gameInstance.configuration.find(s => s.id === targetId)

      if (sourceOption.target && targetOption.target && sourceOption.target === targetOption.target) {
        isCorrectMatching = true;
        correctDroppableBrand = targetId;
      }
    }

    return { isCorrectMatching, correctDroppableBrand };
  }

  function getOnlyId(id)
  {
     return id.replaceAll('-source','').replaceAll('-target','');
  }

  useEffect(()=>{
    if (showConfetti && audioRef.current) {
      audioRef.current.src = correctAnswerSound;
      audioRef.current.play();
    }
  }, [showConfetti]);

  function drop(event: any) {
    event.preventDefault();
    event.target.classList.remove('droppable-hover');
    const draggableElementBrand = event.dataTransfer.getData('text');
    const droppableElementBrand = event.target.getAttribute('data-brand');
    const { isCorrectMatching, correctDroppableBrand } = checkIfCorrectAnswer(draggableElementBrand, droppableElementBrand);
    total++;
    if (isCorrectMatching && correctDroppableBrand) {
      const draggableElement = document.getElementById(draggableElementBrand);
      event.target.classList.add('dropped');      
      draggableElement.classList.add('dragged');
      draggableElement.setAttribute('draggable', 'false');   
      $('#'+draggableElementBrand).closest(".source-header-text-container").css( "opacity", "0" ); 
      $('#'+draggableElementBrand).closest(".source-header-image-container").css( "opacity", "0" );         
      let element = gameInstance.configuration.find((s: any) => s.id === getOnlyId(draggableElementBrand));
      let {resultHTML} = getElementHTML(element, elementType.target, false, true);
      event.target.innerHTML = resultHTML;
      event.target.style.border = "3px solid #0C6EB7";     
      correct++;
    }
    scoreSection!.style.opacity = '0';
    setTimeout(() => {
      correctSpan!.textContent = correct.toString();
      totalSpan!.textContent = total.toString();
      scoreSection!.style.opacity = '1';
      if (correct == getAnswerCount()) {
        correctAnswerSpan!.classList.add('all-correct-answer');
        $('#success-message').css('display','flex');
        $('#draggable-items').css('display','none');       
        setShowConfetti(true);       
      }
    }, 200);
  }

  function getAnswerCount()
  {
    let totalCount = gameInstance.configuration.reduce(function(sum, record) {
      return (record.target && record.target !== '') ? sum + 1 : sum;}, 0);
    return totalCount;
  }

  // Other Event Listeners
  playAgainBtn?.addEventListener('click', playAgainBtnClick);
  function playAgainBtnClick() {
    correct = 0;
    total = 0;
    if(draggableElements){
      draggableItems.style.opacity = '0';
      matchingPairs.style.opacity = '0';
      $('#success-message').css('display','none');
      $('#draggable-items').css('display','flex');   
      setTimeout(() => {
        if(scoreSection){
          scoreSection.style.opacity = '0';
        }
      }, 100);
    }
    
    setTimeout(() => {
      while (draggableItems.firstChild)
        draggableItems.removeChild(draggableItems.firstChild);
      while (matchingPairs.firstChild)
        matchingPairs.removeChild(matchingPairs.firstChild);
      initiateGameInstance(gameInstance);
      correctSpan.textContent = correct.toString();
      correctAnswerSpan!.classList.remove('all-correct-answer');
      totalSpan.textContent = total.toString();
      draggableItems.style.opacity = '1';
      matchingPairs.style.opacity = '1';
      scoreSection.style.opacity = '1';
    }, 500);
  }

  function generateRandomItemsArray(originalArray: any) {
    let res = [];
    let clonedArray = [...originalArray];
    let n = clonedArray.length;
    for (let i = 1; i <= n; i++) {
      const randomIndex = Math.floor(Math.random() * clonedArray.length);
      res.push(clonedArray[randomIndex]);
      clonedArray.splice(randomIndex, 1);
    }
    return res;
  }

  return (
    <>
      <div id="game-main-container" className="container">
        <div id="game-main-area">
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <div className='stylish-header' style={{display:"flex"}}>
              <div id="game-drag-drop-container">
                <span id="game-header" className="game-header">Game</span>
              </div>
              <div className="right-arrow"></div>
            </div>            
            <div>
                <button id="start-over-btn">Start over</button>
            </div>
          </div>
          
          <div id='loader-pane'>
            <CircularProgress sx={{color: profJimBrandColors.orange}} />
          </div>
          <span id="configure-message" className='configure-message' style={{ display: 'none', marginTop:"35px" }}>Please configure game:
            <NavLink exact to={"/configure/?instanceId=" + instanceId + "&gameId=" + gameId} style={{marginLeft:"10px", textDecoration:"none"}}> Configure game </NavLink>
          </span>
          <div id="game-pane" style={{ display: 'none' }}>
            <div id="game-instructions"></div>
            <div id="game-sentence"></div>
            <span className="score" style={{display:"none"}}>
              <div>
                <span className='correct-answer-span'>Correct:<span className="correct-answer">0</span></span> &nbsp;/&nbsp; <span> Attempt:<span
                  className="total-attempt">0</span></span>
              </div>
              <div>
                <button id="start-over-btn">Start over</button>
              </div>
            </span>

            <div className="container" style={{display:"flex", justifyContent:"center",alignItems:"center", flexDirection:"column", marginTop:"25px"}}>
              <div id="matching-pairs" className="matching-pairs target-container" style={{width:"100%"}}></div>
              <div id="draggable-items" className="draggable-items source-container" style={{width:"100%"}}></div>
              <div id="success-message" className='success-message' style={{display:'none'}}>
                Well done!
              </div>
              {showConfetti && <ConfettiCanvas setShowConfetti={setShowConfetti}/>}
              <div>
              <audio
                    ref={audioRef}
                    style={{
                      display: 'none',
                    }}                  
                  ></audio>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DragDropGame;
