export enum games {
  DragDrop = '57c1f561-b359-4323-9e87-7b37306238f4',
}

export const gameProfiles = [
  {
    gameId: games.DragDrop,
    displayName: 'Parts-of-speech tagging',
    image: "https://devprofjim.blob.core.windows.net/appassets/games/drag-and-drop.png", 
  },
];

