//@ts-nocheck
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Divider, Button, TextField, Grid, InputAdornment, Tooltip, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FieldArray, Form, Formik, getIn } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from 'uuid';
import './DragDropConfigure.css';
import { AddOrUpdateGameInstanceConfigurationDTO } from "../../utils/Modals";
import { games } from "../../utils/GamesType";
import { useHistory } from "react-router-dom";
import TextFieldCustomMenu from "./Menu/textFieldCustomMenu";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { checkIfTextIsURL } from "../../utils/utils";
import CircularProgress from '@mui/material/CircularProgress';
import { profJimBrandColors } from '../../theme/variants';
import ReactQuill from 'react-quill';
import 'quill-paste-smart';
import styled from 'styled-components/macro';
import './quillSnow.css'; // ES
import Custom from "./Custom";

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 50px;
    max-height: 300px;
    position: 'relative';
    font-size: 16px;
    width: 992px;
  }
  .quill {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .ql-container.ql-snow {
    border: 0px;
  }
`;


const validationSchema = Yup.object().shape({
    gameOptions: Yup.array().of(
        Yup.object().shape({
            source: Yup.string().required("Sentence required"),
            // target: Yup.string().required("Target is required")
        })
    )
});

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    button: {
        backgroundColor: '#FF5C54 !important',
        color: '#ffffff',
        '&:hover': {
            backgroundColor: '#47897A !important',
            color: '#ffffff',
        },
    },
    field: {
        margin: 10
    }
}));

const DragDropConfigure = () => {
    const history = useHistory();
    const classes = useStyles();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const search = window.location.search;
    const [errorMessage, setErrorMessage] = useState("");
    const searchParams = new URLSearchParams(search);
    const [displayName, setDisplayName] = useState("Parts-of-speech tagging");
    const [instructions, setInstructions] = useState("Please drag-and-drop parts of speech under appropriate words in the sentence.");
    const [sentence, setSentence] = useState("");
    const [gameOptions, setGameOptions] = useState([{
        id: uuidv4(),
        source: "",
        target: ""
    }]);
    const [loaded, setLoaded] = useState(false);
    const instanceId = searchParams.get('instanceId');
    const gameId = searchParams.get('gameId');
    const queryStringLoaded = useRef<boolean>(false);
    const formRef = useRef();
    let toolBarSentenceId: string = "toolbar-sentence";


    const getToolbarSettings = useCallback(id => {
        return {
            toolbar: {
                container: '#' + id,
            },
            clipboard: {              
                // toggle to add extra line breaks when pasting HTML:
                allowed: {
                  tags: [ 'i', 'u'],
                  attributes: [],
                },                
              },
        };
    },[]);

    useEffect(() => {
        if (instanceId && !queryStringLoaded.current) {
            queryStringLoaded.current = true;
            getGameConfigurationByGameInstanceIdFromParent(instanceId);
        }
        return () => {
            removeIFrameEvent(window, 'message', bindEvent);
        }
    }, []);

    const loadGameBasedOnGameInstanceId = (response) => {
        try {
            if (response && response.data && response.data.gameInstanceConfiguration && response.data.gameInstanceConfiguration.configuration) {
                setGameOptions([...JSON.parse(response.data.gameInstanceConfiguration.configuration)]);

                if (response.data.gameInstanceConfiguration.displayName)
                    setDisplayName(response.data.gameInstanceConfiguration.displayName);

                if (response.data.gameInstanceConfiguration.instructions)
                    setInstructions(response.data.gameInstanceConfiguration.instructions);

                if (response.data.gameInstanceConfiguration.sentence)
                    setSentence(response.data.gameInstanceConfiguration.sentence);
            }
            setLoaded(true);
        }
        catch (err) {
        }
    }

    // Parent Communication---------------------------------------------------------
    const addOrUpdateGameInstanceConfigurationFromParent = (gameConfiguration) => {
        let sendData = { messageType: 'addOrUpdateGameInstanceConfigurationFromParent', instanceId: instanceId, gameConfiguration: gameConfiguration };
        window.parent.postMessage(sendData, '*')
    }

    const getGameConfigurationByGameInstanceIdFromParent = (instanceId) => {
        let sendData = { messageType: 'getGameConfigurationByGameInstanceIdFromParent', instanceId: instanceId };
        window.parent.postMessage(sendData, '*')
    }

    useEffect(() => {
        bindIframeMessageReciever();
    });

    useEffect(() => {
        if (success) {
            history.push('/?instanceId=' + instanceId)
        }
    }, [success])

    const bindIframeMessageReciever = () => {
        bindIFrameEvent(window, 'message', bindEvent);
    }

    function bindEvent(e: any) {
        if (e.data && e.data.type != 'webpackWarnings') {
            try {
                const { messageType } = JSON.parse(e.data);
                if (messageType) {
                    if (messageType === 'getGameConfigurationByGameInstanceIdFromParentData') {
                        const { response, returnInstanceId } = JSON.parse(e.data);
                        if (response && instanceId && returnInstanceId && instanceId === returnInstanceId) {
                            loadGameBasedOnGameInstanceId(response);
                        }
                    }
                    else if (messageType === 'addOrUpdateGameInstanceConfigurationFromParent') {
                        const { response, returnInstanceId } = JSON.parse(e.data);
                        if (response && instanceId && returnInstanceId && instanceId === returnInstanceId) {
                            setSuccess(true);
                            setError(false);
                            setErrorMessage("");
                        }
                        else {
                            setError(true);
                            setErrorMessage("Error while saving.");
                        }
                    }
                    else if (messageType === 'selectedGameOptionsImageFromParent') {
                        const { response, gameOptionId, returnInstanceId } = JSON.parse(e.data);
                        if (response && instanceId && returnInstanceId && instanceId === returnInstanceId && gameOptionId &&
                            formRef.current && formRef.current.values && formRef.current.values.gameOptions && formRef.current.values.gameOptions.length > 0) {
                            let clonedGameOptions = [...formRef.current.values.gameOptions];
                            for (let index = 0; index < clonedGameOptions.length; index++) {
                                if (gameOptionId === clonedGameOptions[index].id + '-source') {
                                    clonedGameOptions[index].source = response;
                                    setGameOptions(clonedGameOptions);
                                }
                                else if (gameOptionId === clonedGameOptions[index].id + '-target') {
                                    clonedGameOptions[index].target = response;
                                    setGameOptions(clonedGameOptions);
                                }
                            }
                            formRef.current.validateForm();
                        }
                    }
                }
            }
            catch (err) {

            }
        }
    }

    function bindIFrameEvent(element: any, eventName: any, eventHandler: any) {
        if (element.addEventListener) {
            element.addEventListener(eventName, eventHandler, false);
        }
        else if (element.attachEvent) {
            element.attachEvent('on' + eventName, eventHandler);
        }
    }

    function removeIFrameEvent(element: any, eventName: any, eventHandler: any) {
        element.removeEventListener(eventName, eventHandler, false);
    }
    // Parent Communication---------------------------------------------------------

    const deleteGameOptionImage = (gameOptionId, type) => {
        if (gameOptions && gameOptions.length > 0) {
            let clonedGameOptions = [...gameOptions];
            for (let index = 0; index < clonedGameOptions.length; index++) {
                if (gameOptionId === clonedGameOptions[index].id && type === 'source') {
                    clonedGameOptions[index].source = '';
                    formRef.current.setFieldTouched(`gameOptions[${index}].source`, true);
                    setGameOptions(clonedGameOptions);
                }
                else if (gameOptionId === clonedGameOptions[index].id && type === 'target') {
                    clonedGameOptions[index].target = '';
                    //formRef.current.setFieldTouched(`gameOptions[${index}].target`, true);
                    setGameOptions(clonedGameOptions);
                }
            }
            formRef.current.validateForm();
        }
    }

    return (
        <div id="configure-game-header" className="container">
            <div id="configure-game-main-area">
                <div>
                    <span id="configure-game-header" className="game-header" style={{ color: profJimBrandColors.purple, marginTop: "30px" }}>Game configuration</span>
                </div>
                {!loaded && <div id='loader-pane'>
                    <CircularProgress sx={{ color: profJimBrandColors.orange }} />
                </div>}
                {loaded && instanceId &&
                    <div className="container">
                        <div className="row">
                            <div className="col-12" style={{ padding: "40px" }}>
                                <Formik
                                    enableReinitialize={true}

                                    initialValues={{
                                        displayName,
                                        instructions,
                                        sentence,
                                        gameOptions
                                    }}
                                    innerRef={formRef}
                                    validationSchema={validationSchema}
                                    onSubmit={async (values) => {
                                        if (!instanceId) {
                                            setError(true);
                                            setErrorMessage("Game InstanceId not available");
                                        }
                                        else if (values && values.gameOptions && values.gameOptions.length > 1) {
                                            setSuccess(false);
                                            setError(false);
                                            let gameConfiguration: AddOrUpdateGameInstanceConfigurationDTO = {
                                                instanceId: instanceId,
                                                gameId: games.DragDrop,
                                                displayName: values.displayName,
                                                instructions: values.instructions,
                                                sentence: values.sentence,
                                                configuration: JSON.stringify(values.gameOptions),
                                            }
                                            addOrUpdateGameInstanceConfigurationFromParent(gameConfiguration);
                                        }
                                    }}
                                >
                                    {({ values, touched, errors, handleChange, handleBlur, isValid, setFieldValue, setFieldTouched }) => (
                                        <Form noValidate autoComplete="off">
                                            <Grid container sx={{ alignItems: 'center' }}>
                                                <Grid item sx={{ marginRight: '20px' }}>
                                                    <TextField
                                                        margin="normal"
                                                        variant="standard"
                                                        size="small"
                                                        label="Game Title"
                                                        name='displayName'
                                                        value={values.displayName}
                                                        helperText={
                                                            errors.displayName
                                                                ? errors.displayName
                                                                : ""
                                                        }
                                                        error={Boolean(touched.displayName && errors.displayName)}
                                                        required
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        sx={{
                                                            width: 1135,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container sx={{ alignItems: 'center' }}>
                                                <Grid item sx={{ marginRight: '20px' }}>
                                                    <TextField
                                                        margin="normal"
                                                        variant="standard"
                                                        size="small"
                                                        label="Game instructions"
                                                        name='instructions'
                                                        value={values.instructions}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        sx={{
                                                            width: 1135,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container sx={{ alignItems: 'center' }}>
                                                <Grid item sx={{ marginRight: '20px', width: '100%' }}>
                                                    <InputLabel sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '13px', marginTop: '15px', marginBottom: '5px' }}>Sentence</InputLabel>
                                                    <QuillWrapper>
                                                        <div style={{ display: 'flex' }}>
                                                            <div style={{ flex: 1 }}>
                                                                <div
                                                                    id={toolBarSentenceId}
                                                                    style={{
                                                                        height: '40px',
                                                                        backgroundColor: profJimBrandColors.white,
                                                                        zIndex: 2,
                                                                        borderTopLeftRadius: '10px',
                                                                        borderTopRightRadius: '10px',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        fontSize: '16px',
                                                                    }}
                                                                >
                                                                    <span className="ql-formats">
                                                                        <Tooltip arrow title="Italic">
                                                                            <button className="ql-italic"></button>
                                                                        </Tooltip>
                                                                        <Tooltip arrow title="Underline">
                                                                            <button className="ql-underline" />
                                                                        </Tooltip>
                                                                    </span>
                                                                </div>
                                                                <ReactQuill
                                                                    id="sentence"
                                                                    modules={getToolbarSettings(toolBarSentenceId)}
                                                                    value={values.sentence}
                                                                    onChange={(content, delta, source, editor) => {
                                                                        console.log(editor.getHTML());
                                                                                                                                          
                                                                        setFieldValue('sentence', editor.getHTML());
                                                                    }}
                                                                    style={{
                                                                        backgroundColor: profJimBrandColors.white,
                                                                        color: profJimBrandColors.black,
                                                                        fontSize: '16px'
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </QuillWrapper>
                                                </Grid>
                                            </Grid>
                                            {/* <Divider style={{ marginTop: 20, marginBottom: 10 }} /> */}
                                            <div style={{ marginTop: "20px", marginBottom: "40px" }}>
                                                <span id="configure-game-options-header" className="game-options-header">Configure options</span>
                                            </div>

                                            <FieldArray name="gameOptions" >
                                                {({ push, remove }) => (
                                                    <div>
                                                        {values.gameOptions.map((p, index) => {
                                                            const source = `gameOptions[${index}].source`;
                                                            const sourceOptionId = p.id + '-source';
                                                            const touchedSource = getIn(touched, source);
                                                            const errorSource = getIn(errors, source);

                                                            const target = `gameOptions[${index}].target`;
                                                            const targetOptionId = p.id + '-target';
                                                            // const touchedTarget = getIn(touched, target);
                                                            // const errorTarget = getIn(errors, target);

                                                            return (
                                                                <Grid container key={p.id} sx={{ alignItems: 'flex-start', marginTop: "20px" }}>
                                                                    <Grid item sx={{ marginRight: '5px' }}>
                                                                        <div style={{ width: "40px", height: "40px", backgroundColor: profJimBrandColors.purple, display: "flex", justifyContent: "center", borderRadius: "50%", alignItems: "center", color: "white", fontSize: "20px" }}>{index + 1}</div>

                                                                    </Grid>
                                                                    <Grid item sx={{ marginLeft: "20px", marginRight: '20px' }}>
                                                                        <TextField
                                                                            margin="normal"
                                                                            variant="standard"
                                                                            size="small"
                                                                            label="Sentence (Image URL / Text)"
                                                                            name={source}
                                                                            value={p.source}
                                                                            required
                                                                            helperText={
                                                                                errorSource
                                                                                    ? errorSource
                                                                                    : ""
                                                                            }
                                                                            error={Boolean(touchedSource && errorSource)}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            sx={{
                                                                                width: 300,
                                                                                marginTop: '0px',
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <>
                                                                                        <InputAdornment position="end">
                                                                                            <TextFieldCustomMenu instanceId={instanceId} gameOptionId={sourceOptionId}
                                                                                            />
                                                                                        </InputAdornment>
                                                                                    </>
                                                                                ),
                                                                            }}
                                                                        />
                                                                        {checkIfTextIsURL(p.source) && <div
                                                                            style={{
                                                                                marginBottom: '6px',
                                                                                display: 'flex',
                                                                            }}
                                                                        >
                                                                            <img className="game-option-image" height="110px" src={p.source} />
                                                                            <div>
                                                                                <DeleteIcon
                                                                                    className="delete-game-option-image"
                                                                                    color="action"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => deleteGameOptionImage(p.id, 'source')}
                                                                                />
                                                                            </div>
                                                                        </div>}
                                                                    </Grid>
                                                                    <Grid item sx={{ marginRight: '20px' }}>
                                                                        <TextField
                                                                            margin="normal"
                                                                            variant="standard"
                                                                            size="small"
                                                                            label="Part-of-speech (Image URL / Text)"
                                                                            name={target}
                                                                            value={p.target}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            sx={{
                                                                                width: 300,
                                                                                marginTop: '0px',
                                                                            }}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <>
                                                                                        <InputAdornment position="end">
                                                                                            <TextFieldCustomMenu instanceId={instanceId} gameOptionId={targetOptionId}
                                                                                            />
                                                                                        </InputAdornment>
                                                                                    </>
                                                                                ),
                                                                            }}
                                                                        />
                                                                        {checkIfTextIsURL(p.target) && <div
                                                                            style={{
                                                                                marginBottom: '6px',
                                                                                display: 'flex',
                                                                            }}
                                                                        >
                                                                            <img className="game-option-image" height="110px" src={p.target} />
                                                                            <div>
                                                                                <DeleteIcon
                                                                                    className="delete-game-option-image"
                                                                                    color="action"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => deleteGameOptionImage(p.id, 'target')}
                                                                                />
                                                                            </div>
                                                                        </div>}
                                                                    </Grid>
                                                                    <Grid item sx={{ marginRight: '20px', display: values.gameOptions.length > 1 ? "block" : "none" }}>
                                                                        <Button

                                                                            className={classes.button}
                                                                            margin="normal"
                                                                            type="button"
                                                                            variant="outlined"
                                                                            title="Delete option"
                                                                            sx={{
                                                                                width: '30px',
                                                                                backgroundColor: '#FF5C54',
                                                                                color: '#ffffff'
                                                                            }}
                                                                            onClick={() => remove(index)}
                                                                        >
                                                                            <DeleteIcon sx={{ color: profJimBrandColors.whiteF }} />
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                        {values && values.gameOptions && values.gameOptions.length <= 11 &&
                                                            <Grid container sx={{ marginLeft: '70px', marginTop: '10px' }}>
                                                                <Grid item>
                                                                    <Button
                                                                        startIcon={<AddIcon />}
                                                                        className={classes.button}
                                                                        type="button"
                                                                        variant="contained"
                                                                        onClick={() => push({ id: uuidv4(), source: "", target: "" })}
                                                                    >
                                                                        Add Option
                                                                    </Button></Grid></Grid>}
                                                    </div>
                                                )}
                                            </FieldArray>
                                            <Grid container sx={{ marginTop: "30px", width: "100%", justifyContent: "center" }}>

                                                <Grid item>
                                                    <Button
                                                        startIcon={<ArrowBackIcon />}
                                                        className={classes.button}
                                                        type="submit"
                                                        variant="contained"
                                                        onClick={() => { history.push('/?instanceId=' + instanceId); }}

                                                    >
                                                        Return
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        style={{ marginLeft: 15 }}
                                                        startIcon={<SaveIcon />}
                                                        className={classes.button}
                                                        type="submit"
                                                        variant="contained"
                                                        disabled={!isValid || values.gameOptions.length <= 1 || values.displayName.length == 0}
                                                    >
                                                        Save Game
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>}
                <div id="configure-game-drag-drop-container" style={{ paddingBottom: 20 }}>
                    {success && <span className="success-message">Configuration saved successfully !!</span>}
                    {error && errorMessage && <span className="error-message">{errorMessage}</span>}
                </div>
            </div>
        </div>
    );
};

export default DragDropConfigure;
