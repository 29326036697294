import React, { useEffect } from 'react';

const confetti = require('canvas-confetti');

const ConfettiCanvas = (props : any) => {
    useEffect(() => {
        const canvas = document.getElementById('confetti-canvas');
        if (canvas) {
            let myConfetti = confetti.create(canvas, {
                resize: true,
                useWorker: true,
            });

            myConfetti({
                particleCount: 1000,
                spread: 160,
                // any other options from the global
                // confetti function
            });

            setTimeout(() => {
                myConfetti.reset();
                if (props.setShowConfetti) {
                    props.setShowConfetti(false);
                }
            }, 3000);
        }
    }, []);

    return (
        <canvas
            id="confetti-canvas"
            style={{
                position: 'absolute',
                width: '1280px',
                height: '720px',
                left: 0,
            }} />
    );
};

export default ConfettiCanvas;